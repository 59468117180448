<template>
    <main style="background: white; height: 100vh">
        <BackHeader>
            <template v-slot:center>
                <img :src="_logo" alt="Dosi-in">
            </template>
        </BackHeader>
        <h1 class="login-title">Tạo tài khoản mới</h1>
        <RegisterFrom />
    </main>
</template>

<script>
    import RegisterFrom from '@/components/forms/RegisterForm'; 
    import BackHeader from '@/components/common/BackHeader';
    export default {
        components:{
            RegisterFrom,
            BackHeader
        },
        setup() {
            
        },
    }
</script>