<template>
    <form @submit.prevent="handleRegister">
        <div class="dosiin_pd-16">
            <template v-if="step == 1">
                <vue-tel-input 
                    v-model="registerUser.phone" 
                    :onInput="onInputPhone"
                    :dropdownOptions="{
                        showFlags : true,
                        showDialCodeInSelection: true,
                    }"

                    :inputOptions="{
                        placeholder : 'Nhập số điện thoại của bạn'
                    }"
                >
                </vue-tel-input>

                <div id="recaptcha-container"></div>
            </template>

            <template v-if="step == 2">
                <VueOtp2 
                    ref="otpInput"
                    input-classes="otp-input"
                    separator=""
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-failed="backToStep"
                    @on-complete="verifyOtp"
                    :phone="phoneObj.number"
                    :timer="40"
                    v-model="loading"
                />
            </template>

            <template v-if="step == 3">
                <div class="form-group" :class="!validation.email ? 'error' : ''">
                    <input 
                        v-model.trim="registerUser.email" 
                        class="form-control" 
                        type="text" 
                        required
                        ref="email"
                    />
                    <label for="email">Email</label>
                </div>

                <div class="form-group" :class="!validation.password ? 'error' : ''">
                    <input 
                        v-model.trim="registerUser.password" 
                        class="form-control" 
                        type="password" 
                        required
                        v-password
                        ref="password"
                    />
                    <label for="name">Mật khẩu</label>
                    <i class="delete">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 10.5003C1.66667 5.90553 5.40521 2.16699 10 2.16699C14.5948 2.16699 18.3333 5.90553 18.3333 10.5003C18.3333 15.0951 14.5948 18.8337 10 18.8337C5.40521 18.8337 1.66667 15.0951 1.66667 10.5003ZM10 2.79922C5.75331 2.79922 2.29889 6.25363 2.29889 10.5003C2.29889 14.747 5.75331 18.2014 10 18.2014C14.2467 18.2014 17.7011 14.747 17.7011 10.5003C17.7011 6.25363 14.2467 2.79922 10 2.79922ZM7.84099 8.34131C7.96499 8.21731 8.16321 8.21731 8.28721 8.34131L10 10.0541L11.7128 8.34131C11.8368 8.21731 12.035 8.21731 12.159 8.34131C12.283 8.46531 12.283 8.66353 12.159 8.78753L10.4461 10.5005L12.166 12.2133C12.2899 12.3373 12.2899 12.5354 12.1659 12.6593C12.1038 12.7215 12.0242 12.7523 11.9411 12.7523C11.8579 12.7523 11.7784 12.7215 11.7162 12.6593L10.0034 10.9465L8.29065 12.6593C8.22852 12.7215 8.14895 12.7523 8.06582 12.7523C7.98269 12.7523 7.90313 12.7215 7.84099 12.6593C7.71699 12.5353 7.71699 12.3371 7.84099 12.2131L9.55379 10.5003L7.84099 8.78753C7.71699 8.66353 7.71699 8.46531 7.84099 8.34131Z" fill="#222222"></path>
                        </svg>
                    </i>
                    <span class="show-pass"><span class="text-pink-gradient">Hiện</span></span>
                </div>

                <div class="form-group" :class="!validation.firstname ? 'error' : ''">
                    <input 
                        v-model.trim="registerUser.firstname" 
                        class="form-control" 
                        type="text" 
                        ref="firstname"
                        required
                    />
                    <label for="name">Họ và tên</label>
                </div>
                
                <div class="d-flex group-select">
                    <div class="form-group grid-item input-50 d-flex" :class="!validation.birthday ? 'error' : ''">
                        <input 
                            v-model.trim="registerUser.birthday" 
                            class="form-control" 
                            ref="birthday"
                            type="date"
                        />

                        <label>Ngày sinh </label>
                    </div>

                    <select class="form-group input-50 d-flex group-select-sex" v-model.trim="registerUser.sex" >
                        <option value="" selected >Giới tính</option>
                        <option value="male">Nam </option>
                        <option value="femate">Nữ</option>
                    </select>
                </div>
            </template>

            <button class="primary-button button btn-login" type="submit"> 
                <span v-if="!loading" class="primary-link_text" v-text="step == 3  ? 'Hoàn tất đăng ký' : 'Tiếp tục'"></span>
                <pulse-loader v-else :loading="loading"></pulse-loader>
            </button>

            <div class="line_wapper">
                <hr class="line dosiin_mt-16">
            </div>

            <div class="register-link dosiin_text-center">
                Bạn đã có tài khoản? 
                <router-link :to="{name : 'login'}" class="primary-link_text">Đăng nhập</router-link>
            </div>

            <div class="condition">
                Bằng cách tiếp tục bạn đã Đồng ý với <br>
                <a href="">Điều khoản dịch vụ, Chính sách bảo mật Dosiin</a>
            </div>
        </div>
    </form>
</template>

<script>
    import { reactive, ref , inject , onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter , useRoute } from 'vue-router';
    import AuthService from '@/services/auth';
    import * as validator from '@/utlis/validation';
    import { useToast } from "vue-toastification";
    import VueTelInput   from '@/components/forms/TelInput';
    import VueOtp2 from "@/components/forms/OTP.vue";
    import { getAuth , RecaptchaVerifier , signInWithPhoneNumber } from "firebase/auth";
    import ClickToLogin from '@/components/toasts/ClickToLogin';

    export default {
        inject: ['mainRoute'],
        components:{
            VueOtp2,
            VueTelInput
        },
        setup(){
            const toast = useToast();
            const store = useStore();
            const router = useRouter();
            const route  = useRoute();
            const registerUser = reactive({
                email    : null,
                password : null,
                firstname: null,
                phone    : '',
                birthday : null,
                sex      : null 
            });

            const validation = reactive({
                email    : true,
                password : true,
                firstname: true,
                phone    : true,
                birthday : true,
                sex      : true 
            });
            
            const email     = ref(null);
            const password  = ref(null);
            const firstname = ref(null);
            const birthday  = ref(null);
            const loading   = ref(false);
            const mainRoute = inject('mainRoute');
            const step      = ref(1);
            const phoneObj  = ref({});
            const authFirebase = getAuth();

            onMounted(() => {
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        onSignInSubmit();
                    }
                }, authFirebase);
            });

            async function handleRegister(){

                // validation 
                if(step.value == 1 ){
                    validation.phone     = validator._isVietnamesePhoneNumber(registerUser.phone.replace(/ +/g,''));
                    if(!registerUser.phone || !validation.phone){
                        toast.error('Số điện thoại không đúng định dạng');
                        return;
                    }
                    loading.value = true;
                    // check phone exits 
                    const res = await AuthService.checkUserExistWithPhone(registerUser.phone.replace(/ +/g,''));

                    if(res.data && !res.data.userExist){
                        sendOtp();
                    }else{
                        loading.value = false;
                        toast.error({component : ClickToLogin});
                    }

                }else if(step.value == 2){

                }else if(step.value == 3){

                    validation.email     = validator._isEmail(registerUser.email);
                    validation.password  = validator._minLength(registerUser.password , 6);
                    // wrong email
                    if(!registerUser.email || !validation.email){
                        email.value.focus();
                        toast.error('Email không đúng định dạng');
                        return;
                    }

                    if(!registerUser.password || !validation.password){
                        password.value.focus();
                        toast.error('Mật khẩu phải có ít nhất 6 ký tự');
                        return;
                    }

                    if(!registerUser.firstname){
                        firstname.value.focus();
                        toast.error('Vui lòng nhập tên của bạn');
                        return;
                    }

                    if(!registerUser.birthday){
                        birthday.value.focus();
                        toast.error('Vui lòng chọn ngày sinh');
                        return;
                    }

                    loading.value = true;

                    const response = await AuthService.register(registerUser);

                    if(response && response.data){
                        if(response.data.message){
                            toast.success(response.data.message);
                        }

                        const loginRes = await AuthService.login({
                            user_login : registerUser.phone.replace(/ +/g,''),
                            password   : registerUser.password
                        });

                        if(typeof loginRes == 'undefined'){
                            loading.value = false;
                        }else{

                            if (loginRes.data.accessToken) {
                                localStorage.setItem('user', JSON.stringify(loginRes.data.user));
                            }
                            
                            store.dispatch('account/login' ,loginRes.data.user);
                            loading.value = false;

                            if(route.query.next){
                                // router.replace(decodeURIComponent(route.query.next));
                                window.location.href = decodeURIComponent(route.query.next);
                            }else{
                                router.push({name : mainRoute});
                            }
                        }
                    }else{
                        loading.value = false;
                    }
                }
            }

            async function sendOtp(){
                let phoneNumber = phoneObj.value.number;
                const appVerifier = window.recaptchaVerifier;
                await signInWithPhoneNumber(authFirebase , phoneNumber, appVerifier)
                    .then(function (confirmationResult) {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        window.confirmationResult = confirmationResult;
                        toast.success('Vui lòng nhập mã xác thực đã được gửi tới số điện thoại của bạn');
                        step.value = 2;
                        loading.value = false;
                    }).catch(function (error) {
                        toast.error('Lỗi , không thể gửi tin nhắn đến số điện thoại này');
                        loading.value = false;
                    });
            }

            function verifyOtp(value){
                window.confirmationResult.confirm(value).then(async function (result) {
                    // User signed in successfully.
                    let userOTP = result.user;
                    if(userOTP){
                        step.value = 3;
                    }
                }).catch(function (error) {
                    loading.value = false;
                    toast.warning('Mã xác thực không đúng vui lòng kiểm tra lại');
                    // User couldn't sign in (bad verification code?)
                });
            }

            function onInputPhone(phoneOrigin, phoneObject, input) {
                if (phoneObject?.formatted) {
                    registerUser.phone = phoneObject.formatted;
                    phoneObj.value = phoneObject;
                }
            }

            const backToStep = (value) => {
                step.value = value;
            }

            return {
                registerUser,  
                loading,
                step,   
                phoneObj,           
                // refs 
                email,
                password,
                birthday,
                firstname,
                // methods
                onInputPhone,
                handleRegister,
                validation,
                verifyOtp,
                backToStep
            }
        },
    }
</script>