<template>
    <RegisterMB v-if="layout == 4"/>
    <RegisterPC v-else/>
</template>

<script>
    import RegisterMB from '@/pages/mb/auth/Register';
    import RegisterPC from '@/pages/pc/auth/Register';
    export default {
        components:{
            RegisterMB,
            RegisterPC
        }
    }
</script>