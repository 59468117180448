<template>
    <div class="sign_inner dosiin_d-flex dosiin_page-min-height dosiin_padding-header-top">
        <div class="sign_left">
            <div class="sign_txt">
                <h3>Hãy đăng ký để có ưu đãi đặc biệt từ chúng tôi</h3>
            </div>
        </div>
        <div class="sign_right">
            <div class="sign_right-box">
                <RegisterFrom />
            </div>
        </div>
    </div>
</template>

<script>
    import RegisterFrom from '@/components/forms/RegisterForm';
    export default {
        components:{
            RegisterFrom
        },
        name: "register",
    }
</script>